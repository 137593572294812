<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">添加</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" v-if="row.parentId === '0'" @click="handleCreateSon(row)">添加子集</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '新增'}`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        top="10vh"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {add, list, view, update, deleteNew} from "@/api/build/metro";

export default {
  name: "metro",
  data(){
    return{
      tableData: [],
      showLoading: false,
      option:{
        title: '设置地铁',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: false, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '名称',
            prop: 'name',
            align: 'left'
          }
        ]
      },
      dialogVisible: false,
      form: {
        id: '',
        name: '',
        cityId: '',
        metroLevel: 1,
        parentId: 0
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '名称',
            prop: 'name',
            maxlength: 10,
            showWordLimit: true,
            append: '字',
            span: 24,
            rules: [{
              required: true,
              message: "请输入标题",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  mounted() {
    // console.log(this.$route.query.id)
    this.form.cityId = this.$route.query.id;
  },
  methods: {
    onLoad(){
      // console.log(this.$route.query.id)
      this.showLoading = true;
      list({id: this.$route.query.id}).then(res=>{
        console.log(res)
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data || [];
        }
      })
    },
    // 添加
    handleCreate(){
      // console.log('添加')
      this.dialogVisible = true;
      this.form.metroLevel = 1;
    },
    // 添加子集
    handleCreateSon(row){
      this.dialogVisible = true
      this.form.parentId = row.id;
      this.form.metroLevel = 2;
    },
    // 编辑
    async handleEdit(row){
      const { data } = await view({id: row.id})
      // console.log(data)
      this.form.id = data.id;
      this.form.name = data.name;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row){
      this.$confirm('是否删除此区县数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // form提交
    submit(form,done){
      // console.log(form)
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('添加成功');
            this.dialogVisible = false;
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.warning(res.msg);
            done();
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('添加成功');
            this.dialogVisible = false;
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.warning(res.msg);
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹框
    handleClose(){
      this.dialogVisible = false;
      setTimeout(()=>{
        this.resetForm();
      },500)
    },
    resetForm(){
      this.form = {
        id: '',
        name: '',
        cityId: this.$route.query.id,
        metroLevel: 1,
        parentId: 0
      }
    }
  }
}
</script>

<style scoped>

</style>